import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"
import Item from "@/components/listings/overview/item"
import { Context } from "@/context/state"
import translate from "@/utils/translation"

const ListingMobile = ({ session, setRegisterModal }) => {
  const { state } = useContext(Context)
  const { listings } = state

  // Initialize Embla Carousel
  const [mainRef, emblaMain] = useEmblaCarousel({
    loop: false,
    skipSnaps: false,
    dragFree: true,
    align: "center",
  })

  const [selectedIndex, setSelectedIndex] = useState(0)

  // Synchronize Main Carousel Selection
  useEffect(() => {
    if (emblaMain) {
      const onSelect = () => {
        setSelectedIndex(emblaMain.selectedScrollSnap())
      }
      emblaMain.on("select", onSelect)

      // Initialize the selected index
      onSelect()

      // Cleanup event listeners on unmount
      return () => {
        emblaMain.off("select", onSelect)
      }
    }
  }, [emblaMain])

  if (!listings || listings.length === 0) return null

  return (
    <App className="d-md-none">
      <div className="ps-4 pe-4 pb-3 header">
        <h3>
          {translate("homepage_listings_recent")}
          <span className="highlight">
            {translate("homepage_listings_recent_highlight")}
          </span>
        </h3>
      </div>
      <SliderWrapper>
        <Embla className="main-slider">
          <Viewport ref={mainRef}>
            <Container>
              {listings.length > 0 ? (
                listings
                  ?.filter(
                    item =>
                      item.status === "investing" || item.status === "funded"
                  )
                  .sort((a, b) => b.status.localeCompare(a.status))
                  .sort((a, b) => {
                    // First, prioritize listings that are not fully funded
                    const aFunded = a.financials?.fundPercent === 100
                    const bFunded = b.financials?.fundPercent === 100
                    if (aFunded !== bFunded) return aFunded - bFunded

                    // Then, sort by the highest fundPercent in descending order
                    return (
                      (b.financials?.fundPercent || 0) -
                      (a.financials?.fundPercent || 0)
                    )
                  })
                  .map((listing, index) => (
                    <SlideItem key={listing.uid} isFirst={index === 0}>
                      <Item
                        listing={listing}
                        session={session}
                        setRegisterModal={setRegisterModal}
                      />
                    </SlideItem>
                  ))
              ) : (
                <LoadingContainer>Loading...</LoadingContainer>
              )}
            </Container>
          </Viewport>
        </Embla>
      </SliderWrapper>
    </App>
  )
}

export default ListingMobile

// Styled Components

const App = styled.div`
  padding: 3rem 0 3rem;

  h3 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .highlight {
    color: #ff8500;
  }

  @media (max-width: 768px) {
    h3 {
      margin-bottom: 0.5rem;
    }
  }
`

const SliderWrapper = styled.div`
  position: relative;
`

const Embla = styled.div`
  position: relative;
`

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  margin-left: -10px;
`

const SlideItem = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto; /* Allow slides to have their own width */
  width: 325px;
  max-width: 325px;
  min-width: 325px;
  padding-left: 15px;
  padding-top: 0.5rem;

  &:first-child {
    padding-left: 25px; /* Equivalent to 1.5rem */
  }

  /* Responsive Adjustments */
  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }

  &:first-child {
    margin-left: 0.5rem;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const Dot = styled.button`
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  padding: 0;
  border: none;
  background: none;
  margin: 0 6px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    fill: ${props => (props.active ? "#000" : "#c4c4c4")};
    transition: fill 0.3s;
  }

  &:focus {
    outline: none;
  }

  &:hover svg {
    fill: #000;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }

  @media (max-width: 480px) {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }
`

const DotSVG = () => (
  <svg viewBox="0 0 10 10" aria-hidden="true" focusable="false">
    <circle cx="5" cy="5" r="5" />
  </svg>
)
